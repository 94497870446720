// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffcc00;
    text-align: center;
  }
  
  .gallery-title {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .gallery-message {
    font-size: 1.5rem;
    margin: 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/Gallery.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,oCAAoC;IACpC,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,SAAS;EACX","sourcesContent":[".gallery-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.8);\n    color: #ffcc00;\n    text-align: center;\n  }\n  \n  .gallery-title {\n    font-size: 3rem;\n    margin-bottom: 20px;\n  }\n  \n  .gallery-message {\n    font-size: 1.5rem;\n    margin: 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
