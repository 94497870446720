import React from 'react';
import './Gallery.css';

function Gallery() {
  return (
    <div className="gallery-container">
      <h1 className="gallery-title">Gallery</h1>
      <p className="gallery-message">This page is currently under construction. Check back soon!</p>
    </div>
  );
}

export default Gallery;
