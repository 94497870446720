import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

function Header() {
  return (
    <div className="header">
      <h1 className="header-title">STAR_ART_HD</h1>
      <h2 className="header-subtitle">STARFOTH</h2>
    </div>
  );
}

function Sidebar() {
  return (
    <div className="sidebar">
      <Link to="/gallery" className="sidebar-button">Gallery</Link>
      <button className="sidebar-button">Worldbuilding</button>
      <button className="sidebar-button">Commissions</button>
      <div className="thumbnail-container">
        <div className="thumbnail"></div>
        <div className="thumbnail"></div>
        <div className="thumbnail"></div>
        <div className="thumbnail"></div>
      </div>
    </div>
  );
}

function QuoteBox() {
  const quotes = [
    "Fortune favors the bold.",
    "Luck is what happens when preparation meets opportunity.",
    "The wheel of fortune turns in your favor.",
    "Bet big, win big.",
  ];

  const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];

  return (
    <div className="quote-box">
      {randomQuote}
    </div>
  );
}

function Home() {
  return (
    <div className="app">
      <div className="sidebar-container">
        <Sidebar />
      </div>
      <div className="main-content">
        <Header />
        <QuoteBox />
      </div>
    </div>
  );
}

export default Home;
